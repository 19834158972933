$backgroundPrimary: #fff;
$backgroundSecondary: #1a27c9;
$backgroundTertiary: #ffffe0;
$backgroundGradientPrimary: linear-gradient(
  -45deg,
  #23d5ab,
  #23a6d5,
  #e73c7e,
  #ee7752
);
$backgroundGradientSecondary: linear-gradient(
  -45deg,
  #23d5ab,
  #23a6d5,
  #e73c7e,
  #ee7752
);

$textPrimary: rgba(0, 0, 0, 0.88);
$textSecondary: #db3c00;
$textTertiary: #fece10;

$linkPrimary: #0066cc;
$linkSecondary: #0066cc;
$linkActivePrimary: #db3c00;
$linkActiveSecondary: #db3c00;

$headingPrimary: #db3c00;
$headingSecondary: #db3c00;
$highlightPrimary: #db3c00;
$highlightSecondary: #db3c00;

$borderColor: #e8e8e8;

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundTertiary: $backgroundTertiary;
  backgroundGradientPrimary: $backgroundGradientPrimary;
  backgroundGradientSecondary: $backgroundGradientSecondary;
  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  textTertiary: $textTertiary;
  linkPrimary: $linkPrimary;
  linkSecondary: $linkSecondary;
  linkActivePrimary: $linkActivePrimary;
  linkActiveSecondary: $linkActiveSecondary;
  headingPrimary: $headingPrimary;
  headingSecondary: $headingSecondary;
  highlightPrimary: $highlightPrimary;
  highlightSecondary: $highlightSecondary;
}
