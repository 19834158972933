@import './colors.module.scss';

// css for html elements
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline !important;
  margin: 0 0.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

q,
b {
  color: $highlightPrimary !important;
  font-style: italic;
  font-weight: bold;
}
q {
  font-size: 18px;
}
.link {
  color: $linkPrimary;
  padding: 0 5px;
  cursor: pointer;
}

.signupButton {
  background-color: #1a27c9 !important;
  border-radius: 36px;
  color: #fff !important;
  display: inline-block;
  font-weight: 700;
  margin: 0;
  padding: 10px 15px;
  text-decoration: none !important;
  min-width: 175px;
}

.signupButton:hover {
  color: $textTertiary !important;
}
// css for ant classes

.ant-steps-item-process .ant-steps-item-content div {
  color: $linkActivePrimary !important;
}

.ant-menu-horizontal {
  border-bottom: none;
  background: transparent !important;
  .ant-menu-title-content {
    color: #fff !important;
  }
  .ant-menu-item-icon {
    color: #fff !important;
  }
  .ant-menu-item-selected span {
    color: #fff !important;
    font-weight: bold;
    text-decoration: underline !important;
    text-underline-offset: 3px;
  }
}

.ant-menu a {
  text-decoration: none !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: 0px !important;
  transition: none !important;
}
.ant-menu-item-active {
  color: $textSecondary !important;
}
.ant-menu-item-icon {
  color: grey !important;
}

.anticon-menu {
  color: #fff;
}

.ant-typography {
  color: $textPrimary;
}

.ant-modal {
  .ant-modal-title {
    margin-bottom: 24px;
    border-bottom: 1px solid;
    padding-bottom: 3px;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.ant-input-number {
  width: 100%;
}

.recaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  > div {
    transform: scale(0.7);
    transform-origin: center 0;
  }
}

.ant-btn {
  -webkit-tap-highlight-color: transparent;
}

.ant-btn-link {
  color: $linkPrimary;
  text-decoration: underline;
  display: inline-block;
  text-underline-offset: 0.2rem;
  > span {
    display: block;
  }
}
.ant-btn-circle {
  width: 21px !important;
  height: 21px !important;
  min-width: 21px !important;
  min-height: 21px !important;
  padding: 0px;
  background: transparent;
  color: $textSecondary;
  border-color: $textSecondary;
  font-weight: bold;
  position: relative;
  top: 1px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: $textSecondary;
}

.ant-form-item .ant-form-item-label,
.ant-form-horizontal .ant-form-item-label {
  min-width: 20%;
  text-align: left;
}

.ant-input-outlined:focus-within,
.ant-input-outlined:hover {
  border-color: #0071e3;
}
.ant-tabs-nav {
  margin-bottom: 8px !important;
}
.ant-tabs .ant-tabs-tab {
  padding: 0 0 6px;
}
.optionIcon {
  color: $textSecondary;
}
